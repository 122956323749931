import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./css/main.css";

const Contact = () => {
  const[name, setName] = useState("");
  const[email, setEmail] = useState("");
  const[query, setQuery] = useState("");

async function emailSender(){
  let item = {name,email,query};
  let result = await fetch("https://scientificbit.com/api/send-email",{
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      "Content-Type" : 'application/json',
      "Accept" : 'application/json'
    }
  }).then(res=>{document.getElementById("success").style.display = "block";});
}


  return (
    <div>
      <br />
      <br />
      <Container>
        <Row className="justify-content-md-center my-3 top-padding">
          <Col xs="auto">
            <h3 className="text">Contact Us</h3>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
          <div className="alert alert-success" id="success" style={{display: 'none'}}>
          <strong>Success!</strong> Your Query has been submitted successfully.
          </div>
          </Col>
        </Row>
         <Row className="justify-content-md-center">
          <Col xs={12} md={6} className="my-5">
            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="text">Full Name</Form.Label>
                <Form.Control type="text" value={name} onChange={(e)=> setName(e.target.value)} placeholder="Enter your full name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="text">Email address</Form.Label>
                <Form.Control type="email" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Enter email" />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1">
                <Form.Label className="text">Your Query / Concern</Form.Label>
                <Form.Control as="textarea"  value={query} onChange={(e)=> setQuery(e.target.value)} rows={3} />
              </Form.Group>
              <div className="text-center">
                <Button variant="outline-danger" onClick={emailSender} id="hi">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <br />
      <br />
    </div>
  );
};

export default Contact;

import React from "react";
import "./css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { GoMail, GoLocation } from "react-icons/go";
import { FiPhoneCall, FiArrowUpCircle } from "react-icons/fi";

const Footer = () => {
  return (
    <div>
      <Container fluid className="body">
        <br />
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={5} className="my-3">
              <h2 className="">
                Scientific<strong className="red">Bit</strong>
              </h2>
              <h6 className="light-text">Software Solutions and Services</h6>
              <br />
              <p>
              Scientific Bit (Private) Limited started with the aim to put core
              software engineering into practice by building some in-house
              solutions and providing innovative solutions to our customers.
              Scientific Bit is founded by three Techies, having 16 years of
              Software development experience, which includes Mobile Apps, 
              Web Development, Backend systems, and much more. 
              </p>
            </Col>

            <Col xs={12} md={2} className="my-3">
              <h4 className="text">Follow Us</h4>
              <br />
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  size="lg"
                  color="#4267B2"
                  icon={faFacebook}
                ></FontAwesomeIcon>
                <strong className="item">Facebook</strong>
              </a>
              <br />
              <br />
              <a
                href="https://www.twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  size="lg"
                  color="#1DA1F2"
                  icon={faTwitter}
                ></FontAwesomeIcon>
                <strong className="item">Twitter</strong>
              </a>
              <br />
              <br />
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  size="lg"
                  color="#0077b5"
                  icon={faLinkedinIn}
                ></FontAwesomeIcon>
                <strong className="item">LinkedIn</strong>
              </a>
              <br />
              <br />
        
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  size="lg"
                  color="#fb3958"
                  icon={faInstagram}
                ></FontAwesomeIcon>
                <strong className="item">Instagram</strong>
              </a>
              <br />
            </Col>

            <Col xs={12} md={5} className="my-3">
              <h4 className="text">CONTACT US</h4>
              <div className="contact-item">
              <GoLocation size={30} color={"firebrick"} />
              <span className="item contact">A-21, Munir Gardens, Block 18<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gulistan e Johar,<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Karachi-75290</span>
              </div>

              <div className="contact-item">
              <GoMail size={30} color={"firebrick"} />
              <span className="item contact">info@scientificbit.com</span>
              </div>

              <div className="contact-item">
                <FiPhoneCall size={30} color={"firebrick"} />
                <span className="item contact">+92 321 376 5427</span>
              </div>
            </Col>
          </Row>
          <br />
          <a href="#" className="fixed">
            <FiArrowUpCircle size={30} color={"firebrick"} />
          </a>
          <br />
          <hr />
          <Row className="justify-content-md-center text-center">
            <h6 className="text">
              © Copyright ScientificBit. All Rights Reserved
            </h6>
          </Row>
          <br />
        </Container>
      </Container>
    </div>
  );
};

export default Footer;
